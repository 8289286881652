<template>
  <div class="container">
    <div class="top_container">
      <a-icon @click="goProductList" class="back_btn" type="left"/>
      <span>用户中心</span>
    </div>
    <a-form-model ref="form" :model="form" :rules="rules">
      <div class="form_container mt10">
        <a-form-model-item >
          {{ form.name }}
        </a-form-model-item>
        <a-form-model-item label="手机号" prop="mobile">
          <a-input v-model="form.mobile" readOnly />
        </a-form-model-item>
        <a-form-model-item label="用户ID" prop="uid" readOnly>
          <a-input v-model="form.uid"/>
        </a-form-model-item>  
        <a-form-model-item label="旧密码" prop="now_pass">
          <a-input type="password" v-model="form.now_pass"/>
        </a-form-model-item>
        <a-form-model-item label="新密码" prop="new_pass">
          <a-input type="password" v-model="form.new_pass"/>
        </a-form-model-item>
        <a-form-model-item label="确认密码" prop="confirm_pass">
          <a-input type="password"  v-model="form.confirm_pass"/>
        </a-form-model-item>
      </div>
    </a-form-model>
    <div style="padding: 20px;text-align: center">
      <a-button type="primary" :loading="loading" :disabled="loading" @click="onSubmit">修改</a-button>
      <a-button type="primary" :loading="loading" :disabled="loading" @click="onQuit" style="margin-left:20px;">退出</a-button>
    </div>
  </div>
</template>
<script>
import request from '@/utils/request';
import enums from "../utils/enums";
import cookies from 'vue-cookies'
export default {
  data() {
    return {
      enums,
      form: { mobile:cookies.get('mobile'),
      uid:cookies.get('customer_id'),
      name:cookies.get('name')
      },
      rules: {
        now_pass: [{required: true, message: '请输入现在密码'}],
        new_pass: [{required: true, message: '请输入密码'}],
        confirm_pass: [{required: true, message: '请输入确认密码'}],
        mobile: [
          {required: true, message: '请输入电话'},
        ]       
      },
      loading: false,
     
    }
  },
  created: async function () {
      if(cookies.get('customer_id')=='' || cookies.get('customer_id')==null){
        this.$router.replace({ path: '/login'});
      }
  },
  methods: {
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const data = JSON.parse(JSON.stringify(this.form))
          if(data.pwd!=data.pwdConfirm){
            alert('两次密码不相同！');
            return;
          }
          data.channel=2
          data.enterprise_id=601
          data.token=cookies.get('token')         
          this.loading = true
          request({
            url: 'user/modify_password',
            data: data
          }).then(r => {
            if (r.data.success) {
              alert('修改密码成功！')
              this.$router.go(-1)
            } else if (r.data.error_code === enums.STATE.FEEDBACK_REPORT_FAULT_MOBILE.v) {
              alert(r.data.msg)
            } else if (r.data.error_code === '001') {
              alert('请登录')
              this.$router.replace({ path: '/login'})
            } else {
              alert('网络异常,请稍后重试')
            }
          }).finally(() => {
            this.loading = false
          })
        }
      });
    },
    onQuit() {
        cookies.set('name','',0)
        cookies.set('mobile','',0)
        cookies.set('customer_id','',0)
        cookies.set('token','',0)
        this.$router.replace({ path: '/login'})
    },
    goProductList () {
      this.$router.replace({ path: '/single', query: {
        enterpriseId: enums.STATE.ENTERPRISE_ID.v
      }})
    }
  }
}
</script>
<style lang="less" scoped>
@import "../assets/css/form.less";

.ant-form-item {
  align-items: center;
}

/deep/ .ant-form-item-label {
  label::before {
    display: inline-block;
    margin-right: 11px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '';
  }
  .ant-form-item-required::before {
    display: inline-block;
    margin-right: 4px;
    color: #f5222d;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*';
  }
}

.container {
  padding: 8px;
  padding-top: 58px;

  .form_container {
    background-color: #fff;
    border-radius: 10px;
    .ant-form-item:last-child {
      border-bottom: none;
    }
  }

  .mt10 {
    margin-top: 10px;
  }

  .button-view {
    padding: 0 8px;
    display: flex;
    justify-content: flex-end;
  }

  .form-button {
    display: flex;
    align-items: center;
    margin-left: 5px;
    background: #3573e0;
    color: #fff;
    border-radius: 3px;
    padding: 2px 5px;
    font-size: 14px;
  }
}
</style>
